var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "h-screen flex w-full bg-img vx-row no-gutter items-center justify-center",
    },
    [
      _c(
        "div",
        {
          staticClass: "vx-col sm:w-4/5 md:w-1/2 lg:w-2/5 xl:w-1/4 sm:m-0 m-4",
        },
        [
          _c("vx-card", [
            _c(
              "div",
              {
                staticClass: "full-page-bg-color",
                attrs: { slot: "no-body" },
                slot: "no-body",
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "vx-row no-gutter justify-center items-center",
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "vx-col xs:w-full sm:w-full md:w-full lg:w-full d-theme-dark-bg",
                      },
                      [
                        _c("div", { staticClass: "p-8 login-tabs-container" }, [
                          _c("div", { staticClass: "vx-card__title mb-4" }, [
                            _c("h4", { staticClass: "mb-4" }, [
                              _vm._v("Backup Code Input"),
                            ]),
                            _c("p", [_vm._v("Enter backup code")]),
                          ]),
                          _c("div", [
                            _c(
                              "form",
                              {
                                on: {
                                  submit: function ($event) {
                                    $event.preventDefault()
                                    return _vm.handleSubmit.apply(
                                      null,
                                      arguments
                                    )
                                  },
                                },
                              },
                              [
                                _c("vs-input", {
                                  directives: [
                                    {
                                      name: "validate",
                                      rawName: "v-validate",
                                      value: "required",
                                      expression: "'required'",
                                    },
                                  ],
                                  staticClass: "w-full mt-8",
                                  attrs: {
                                    autofocus: "autofocus",
                                    type: "number",
                                    name: "backupCode",
                                    "icon-no-border": "",
                                    icon: "icon icon-lock",
                                    "icon-pack": "feather",
                                    "label-placeholder": "Enter backup code",
                                  },
                                  model: {
                                    value: _vm.backupCode,
                                    callback: function ($$v) {
                                      _vm.backupCode = $$v
                                    },
                                    expression: "backupCode",
                                  },
                                }),
                                _c(
                                  "div",
                                  { staticClass: "float-right my-5" },
                                  [
                                    _c(
                                      "router-link",
                                      { attrs: { to: "/login/twofactor" } },
                                      [_vm._v("Enter two factor code")]
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  { staticClass: "my-5" },
                                  [
                                    _c(
                                      "router-link",
                                      { attrs: { to: "/login" } },
                                      [_vm._v("Login Page")]
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "vs-button",
                                  {
                                    staticClass: "w-full float-right mb-8",
                                    on: { click: _vm.handleSubmit },
                                  },
                                  [_vm._v("Submit")]
                                ),
                                _c("vs-input", {
                                  staticClass: "hidden",
                                  attrs: { type: "submit", value: "Submit" },
                                }),
                              ],
                              1
                            ),
                          ]),
                        ]),
                      ]
                    ),
                  ]
                ),
              ]
            ),
          ]),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }